import { parseWithZod } from "@conform-to/zod";
import { invariantResponse } from "@epic-web/invariant";
import {
  json,
  type ActionFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
// import { withSentry } from "@sentry/remix";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { z } from "zod";
import { GeneralErrorBoundary } from "./components/error-boundary.tsx";
import { Navbar } from "./components/navbar.tsx";
import { EpicProgress } from "./components/progress-bar.tsx";
import { useToast } from "./components/toaster.tsx";
import { href as iconsHref } from "./components/ui/icon.tsx";
import { EpicToaster } from "./components/ui/sonner.tsx";
import tailwindStyleSheetUrl from "./styles/tailwind.css?url";
import { authenticator, uuidMetadata } from "./utils/auth.server.ts";
import { ClientHintCheck, getHints } from "./utils/client-hints.tsx";
import { getEnv } from "./utils/env.server.ts";
import { honeypot } from "./utils/honeypot.server.ts";
import { i18n, useChangeLanguage } from "./utils/i18n.ts";
import { i18next } from "./utils/i18next.server.ts";
import { getDomainUrl } from "./utils/misc.tsx";
import { useNonce } from "./utils/nonce-provider.ts";
import { setTheme, type Theme } from "./utils/theme.server.ts";
import { makeTimings, time } from "./utils/timing.server.ts";
import { getToast } from "./utils/toast.server.ts";
import { BasicUserSchema, UserBasic } from "./utils/validation/auth-validation.ts";
import { PubNubMain } from "#app/components/client/pubnub-main";
import React from "react";
import { RootContext } from "#app/context/root-context.ts";

export const links: LinksFunction = () => {
  return [
    // Preload svg sprite as a resource to avoid render blocking
    { rel: "preload", href: iconsHref, as: "image" },
    // Preload CSS as a resource to avoid render blocking
    // { rel: "mask-icon", href: "/favicons/mask-icon.svg" },
    // {
    // 	rel: "alternate icon",
    // 	type: "image/png",
    // 	href: "/favicons/favicon-32x32.png",
    // },
    // { rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
    {
      rel: "manifest",
      href: "/site.webmanifest",
      crossOrigin: "use-credentials",
    } as const, // necessary to make typescript happy
    //These should match the css preloads above to avoid css as render blocking resource
    // { rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
    { rel: "stylesheet", href: tailwindStyleSheetUrl },
  ].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? "Kelas Digital" : "Error | Kelas Digital" },
    { name: "description", content: `Kelas Digital Application` },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings("root loader");
  const session = await time(() => authenticator.isAuthenticated(request), {
    timings,
    type: "getSession",
    desc: "getSession in root",
  });

  const user = session ? BasicUserSchema.parse(session) : null;
  if (user) {
    const metadata = await uuidMetadata({ user });
  }
  const locale = await i18next.getLocale(request);
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        locale,
      },
      ENV: getEnv(),
      toast,
      honeyProps,
    },
    {
      headers: toastHeaders ?? {},
    }
  );
}

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    "Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(["system", "light", "dark"]),
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema,
  });

  invariantResponse(submission.status === "success", "Invalid theme received");

  const { theme } = submission.value;

  const responseInit = {
    headers: { "set-cookie": setTheme(theme) },
  };
  return json({ result: submission.reply() }, responseInit);
}

function Document({
  children,
  nonce,
  theme = "light",
  locale = i18n.fallbackLng,
  env = {},
  allowIndexing = true,
}: {
  children: React.ReactNode;
  nonce: string;
  theme?: Theme;
  locale?: string;
  env?: Record<string, string>;
  allowIndexing?: boolean;
}) {
  return (
    <html nonce={nonce} lang={locale} className={`${theme} h-full overflow-x-hidden`}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {allowIndexing ? null : <meta name="robots" content="noindex, nofollow" />}
        <Links />
      </head>
      <body className="bg-background text-foreground">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const allowIndexing = data.ENV.ALLOW_INDEXING !== "false";
  const { locale } = data.requestInfo;
  useChangeLanguage(locale);
  useToast(data.toast);

  const user = data?.user ? { user: data?.user } : null;
  const memoizedData = React.useMemo(() => user, [JSON.stringify(user)]);

  return (
    <Document
      locale={data.requestInfo.locale}
      nonce={nonce}
      allowIndexing={allowIndexing}
      env={data.ENV}
    >
      <div className="flex h-screen flex-col justify-between">
        {data.user && <Navbar />}
        <div className="flex-1">
          <RootContext.Provider value={memoizedData}>
            <Outlet />
          </RootContext.Provider>
          {data.user && <div className="h-[62px] md:h-[0px]" />}
        </div>
      </div>
      <EpicToaster closeButton position="top-center" theme="light" />
      <EpicProgress />
      <PubNubMain />
    </Document>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  );
}

// export default withSentry(AppWithProviders);
export default AppWithProviders;

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  );
}
